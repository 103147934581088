import './App.css';
import * as React from 'react';
import { useEffect, useState } from 'react';
import ResponsiveAppBar from "./appbars/ResponsiveAppBar";
import { fn_url } from "./Config";
import { Link } from "react-router-dom";
import { AccountBalance, PermIdentity } from '@mui/icons-material';

export default function Dashboard() {
  const [members, setMembers] = useState<number | undefined>(undefined);
  const [revenue, setRevenue] = useState<number | undefined>(undefined);

  useEffect(() => {
    loadDashboard();
  }, []);

  const loadDashboard = async () => {
    console.log('getting dashboard data...');
    let data = await fetch(fn_url + "dashboard?walletKey=").then(response => response.json());
    console.log(data);
    setMembers(+data.loyalty.members);
    setRevenue(+data.loyalty.revenue);
  };

  return (
    <div className="App flexColumn justifyStart">
      <ResponsiveAppBar/>

      <div className='title-mb0'>Dashboard</div>

      <div className='flexColumn'>
        <div className='dashboardPanel gap-3'>
          <div className='mb-1'><b>My Profile</b></div>
          <div className='flexRow mb-1 gap-1'>
            <PermIdentity sx={{height: '25px', color: 'black'}} />
            <div>Rafael Camacho</div>
          </div>
          <div className='flexRow gap-1'>
            <img alt='' src='/MyNFA_sports_blue.png' style={{height: '20px'}}/>
            <div>member since January 2024</div>
          </div>
        </div>

        <div className='dashboardPanel'>
          <div className='mb-1'><b>My Data</b></div>
          <div className='mb-1'>You have 2 items in your data vault</div>
          <Link to='/vault'>
            <div className='flexRow'>
              <AccountBalance sx={{height: '25px', color: 'black'}} />
              <div style={{color: '#8c15e9'}}>Access your data vault</div>
            </div>
          </Link>
        </div>

        <div className='flexColumn dashboardPanel justifyStart gap-1'>
          <div><b>Loyalty Platform</b></div>

          <a target='loyalty' style={{color: '#8c15e9'}} href='https://rafaelcamacho.mynfaworld.com'>rafaelcamacho.mynfaworld.com</a>

          <div className='flexRow'>
            {members &&
              <div className='flexColumn gap-0'>
                <div className='heading'>
                  {members}m
                </div>
                <div>Members</div>
              </div>
            }

            {revenue &&
              <div className='flexColumn gap-0'>
                <div className='heading'>
                  £{revenue}k
                </div>
                <div>Revenue</div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

import './App.css';
import * as React from 'react';
import ResponsiveAppBar from "./appbars/ResponsiveAppBar";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import {AccountBalance, DataThresholding, PhotoCamera, SwitchAccount, TaskAlt} from "@mui/icons-material";

export default function Vault() {

  return (
    <div className="App flexColumn justifyStart">
      <ResponsiveAppBar />

      <div className='title-mb0'>Data Vault</div>

      <div className='vaultPanel mb-1'>
        <div><b>Your MyNFA World Data Vault</b></div>
        <div>&nbsp;</div>
        <div>You have 2 items in your data vault</div>

        <div className='vaultItem flexColumn gap-1'>
          <div className='flexRow gap-1 mb-1'>
            <DataThresholding sx={{height: '25px', color: 'black'}} />
            <div>My Fitness Data.txt</div>
          </div>
          <div>Uploaded: 1 February, 2024</div>
          <div className='flexRow gap-1'>
            <div>Your data is secured</div>
            <AccountBalance sx={{height: '25px', color: 'purple'}} />
          </div>
          <div className='flexRow gap-1'>
            <div>Your data is verifiable</div>
            <TaskAlt sx={{height: '25px', color: 'lightgreen'}} />
          </div>
          <div className='flexRow gap-1'>
            <div>Professional Profile</div>
            <SwitchAccount sx={{height: '25px', color: 'darkblue'}} />
          </div>
        </div>

        <div className='vaultItem flexColumn gap-1'>
          <div className='flexRow gap-1 mb-1'>
            <PhotoCamera sx={{height: '25px', color: 'black'}} />
            <div>platinum membership welcome video.mp4</div>
          </div>
          <div>Uploaded: 2 February, 2024</div>
          <div className='flexRow gap-1'>
            <div>Your data is secured</div>
            <AccountBalance sx={{height: '25px', color: 'purple'}} />
          </div>
          <div className='flexRow gap-1'>
            <div>Public Profile</div>
            <SwitchAccount sx={{height: '25px', color: 'darkblue'}} />
          </div>
        </div>

      </div>

      <Link to="/dashboard"><Button variant="contained">OK</Button></Link>

    </div>
  );
}

import '../App.css';
import * as React from 'react';
import { useState } from 'react';
import SimpleAppBar from "../appbars/SimpleAppBar";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import MyConfirmField from "../forms/MyConfirmField";

const validationSchema = yup.object({
  playerConfirm: yup.boolean().isTrue('You must accept this option'),
});

export default function PlayerProfileConfirm() {
  const [page, setPage] = useState(0);

  const navigate = useNavigate();
  const nextPage = async () => {setPage(page + 1)};

  const formik = useFormik({
    initialValues: {playerConfirm: false},
    validationSchema: validationSchema,
    onSubmit: (values) => {console.log(JSON.stringify(values));},
  });

  let x = 0;

  return (
    <div className="App flexColumn justifyStart">
      <SimpleAppBar />

      {page === x++ &&
        <div className='flexColumn justifyStart'>
          <div className='heading'>Confirmation</div>

          <div>Please confirm and sign in the box to confirm we can create your profile</div>

          <MyConfirmField formik={formik} name='playerConfirm' label='I confirm MyNFA World can use the details supplied to create my profile' maxWidth='350px' />

          <div className='flexRow'>
            <Button variant="outlined" onClick={() => navigate(-1)}>Cancel</Button>
            <Button variant="contained" onClick={async () => {
              await formik.submitForm();
              !Object.keys(await formik.validateForm()).length && nextPage();
            }}>Submit</Button>
          </div>
        </div>
      }

      {page === x++ &&
        <div className='flexColumn justifyStart'>
          <div className='heading'>Profile Completed</div>

          <div className='mb-2'>Please click OK to continue</div>

          <Link to="/player/tasks?step=3"><Button variant="contained">OK</Button></Link>
        </div>
      }

    </div>
  );
}

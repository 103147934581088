import { TextField } from "@mui/material";
import * as React from "react";

export default function MyTextField(props: {formik: any, name: string, label: string, required: boolean, type?: string}) {

  return (
    <TextField id="outlined-required" variant="outlined" sx={{width: '19em'}}
               name={props.name} value={props.formik.values[props.name]} label={props.label}
               required={props.required} type={props.type}
               error={props.formik.touched[props.name] && Boolean(props.formik.errors[props.name])}
               helperText={props.formik.touched[props.name] && props.formik.errors[props.name]}
               onChange={props.formik.handleChange}
               onBlur={props.formik.handleBlur}
               autoComplete='off'
    />
    
  );
}
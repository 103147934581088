
export async function checkResponse(response : Response) {
  if (!response.ok) {
    let text = await response.text();
    console.log('error response', text);
    throw new Error('HTTP response error');
  }
  return response;
}


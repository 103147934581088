import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true, fallbackLng: 'en', interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }, resources: {
      en: {
        translation: {
          app: {
            welcome: 'Welcome',
            language: 'Language',
          },
          signup: {
            language: 'Language',
            info1: 'Welcome to MyNFA World',
            info2: 'MyNFA World securely stores your data',
            info3: 'You control access to your data',
            info4: 'Reward your fans via a loyalty program',
          },
        },
      },
      es: {
        translation: {
          app: {
            welcome: 'Bienvenido',
            language: 'Lengua',
          },
          signup: {
            info1: 'Esta es la página de información 1',
            info2: 'Esta es la página de información 2',
            info3: 'Esta es la página de información 3',
            info4: 'Esta es la página de información 4',
          },
        },
      },
      pr: {
        translation: {
          app: {
            welcome: 'Bem-vindo',
            language: 'Lingua',
          },
          signup: {
            info1: 'Esta é a página de informações 1',
            info2: 'Esta é a página de informações 2',
            info3: 'Esta é a página de informações 3',
            info4: 'Esta é a página de informações 4',
          },
        },
      },
    }
  });

export default i18n;
import Button from "@mui/material/Button";
import DoneIcon from "@mui/icons-material/Done";
import * as React from "react";
import { Link } from "react-router-dom";

export default function TickButton(props: {to: string, text: string, disabled: boolean, ticked: boolean}) {
  function buttonContent() {
    return <Button variant={props.disabled ? 'outlined' : 'contained'} className='buttonExtraWide'>
      {props.ticked ?
        <div className='flexRow gap-1'>
          <div style={{width: '24px'}}></div>
          <div>{props.text}</div>
          <DoneIcon style={{color: 'lightgreen'}}/>
        </div> :
        <div>{props.text}</div>
      }
    </Button>;
  }

  return (
    <div>
      {props.disabled ?
        <div>
          {buttonContent()}
        </div>
          :
        <Link to={props.to}>
          {buttonContent()}
        </Link>
      }
    </div>
  );
}
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Signup from "./signup/Signup";
import { createTheme, ThemeProvider } from "@mui/material";
import SignupPlayer from "./signup/SignupPlayer";
import SignupAgent from "./signup/SignupAgent";
import Login from "./Login";
import './i18n';
import PlayerTasks from "./player/PlayerTasks";
import PlayerConnectData from "./player/PlayerConnectData";
import PlayerProfilePersonal from "./player/PlayerProfilePersonal";
import PlayerAuthAgent from "./player/PlayerAuthAgent";
import Dashboard from "./Dashboard";
import Vault from "./Vault";
import Products from "./Products";
import Partnerships from "./Partnerships";
import Marketplace from "./Marketplace";
import NotImplemented from "./NotImplemented";
import PlayerConnectDataWhoop from "./player/PlayerConnectDataWhoop";
import PlayerProfileIdVerification from "./player/PlayerProfileIdVerification";
import PlayerProfileProfessional from "./player/PlayerProfileProfessional";
import PlayerProfileConfirm from "./player/PlayerProfileConfirm";

const theme = createTheme({
  components: {
    MuiInputLabel: {
      styleOverrides: {
        focused: {
          borderColor: 'red',
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input : {
          color: 'white',
          borderColor: 'white'
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root : {
          color: 'white',
          borderColor: 'white'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: "2px solid",
            borderColor: "white"
          }
        },
        notchedOutline: {
          borderColor: "white"
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: 'white'
        }
      }
    }
  },
  typography: {
    button: {
      textTransform: 'none'
    }
  }
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<App/>}/>
          <Route path='/dashboard' element={<Dashboard/>}/>
          <Route path='/login' element={<Login/>}/>
          <Route path='/marketplace' element={<Marketplace/>}/>
          <Route path='/not-implemented' element={<NotImplemented/>}/>
          <Route path='/signup' element={<Signup/>}/>
          <Route path='/signup/agent' element={<SignupAgent/>}/>
          <Route path='/signup/player' element={<SignupPlayer/>}/>
          <Route path='/partnerships' element={<Partnerships/>}/>
          <Route path='/player/auth-agent' element={<PlayerAuthAgent/>}/>
          <Route path='/player/connect-data' element={<PlayerConnectData/>}/>
          <Route path='/player/connect-data/whoop' element={<PlayerConnectDataWhoop/>}/>
          <Route path='/player/profile/confirm' element={<PlayerProfileConfirm/>}/>
          <Route path='/player/profile/personal' element={<PlayerProfilePersonal/>}/>
          <Route path='/player/profile/professional' element={<PlayerProfileProfessional/>}/>
          <Route path='/player/profile/id-verification' element={<PlayerProfileIdVerification/>}/>
          <Route path='/player/tasks' element={<PlayerTasks/>}/>
          <Route path='/products' element={<Products/>}/>
          <Route path='/vault' element={<Vault/>}/>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>);

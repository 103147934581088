import '../App.css';
import * as React from 'react';
import { useState } from 'react';
import SimpleAppBar from "../appbars/SimpleAppBar";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import { ContactMail, Done, PermIdentity, Work } from "@mui/icons-material";

export default function PlayerProfileIdVerification() {
  const [page, setPage] = useState(0);

  const navigate = useNavigate();
  const prevPage = async () => {setPage(page - 1)};
  const nextPage = async () => {setPage(page + 1)};

  let x = 0;

  return (
    <div className="App flexColumn justifyStart">
      <SimpleAppBar />

      {page === x++ &&
        <div className='flexColumn justifyStart'>
          <div className='heading'>Complete your profile</div>

          <div>Please click next to continue</div>

          <div className='flexColumn alignStart mt-2 mb-2'>
            <div className='flexRow completed'>
              <ContactMail /><div>Personal Information</div><Done />
            </div>
            <div className='flexRow completed'>
              <Work /><div>Professional Information</div><Done />
            </div>
            <div className='flexRow'>
              <PermIdentity /><div>ID Verification</div>
            </div>
          </div>

          <div className='flexRow'>
            <Button variant="outlined" onClick={() => navigate(-1)}>Cancel</Button>
            <Button variant="contained" onClick={nextPage}>Next</Button>
          </div>
        </div>
      }

      {page === x++ &&
        <div className='flexColumn justifyStart'>
          <div className='flexRow'>
            <PermIdentity /><div className='heading'>ID Verification</div>
          </div>

          <div>Please take a selfie, ensuring your face is within the frame</div>

          <div className='flexColumn selfiePanel'>
            <img alt='' src='/verify-person.png' />
          </div>

          <div className='flexRow'>
            <Button variant="outlined" onClick={prevPage}>Prev</Button>
            <Button variant="contained" onClick={nextPage}>Next</Button>
          </div>
        </div>
      }

      {page === x++ &&
        <div className='flexColumn justifyStart'>
          <div className='flexRow'>
            <PermIdentity /><div className='heading'>ID Verification</div>
          </div>

          <div>Please confirm that you can be clearly be seen in the box</div>

          <div className='flexColumn selfiePanel'>
            <img alt='' src='/verify-person.png' />
          </div>

          <div className='flexRow'>
            <Button variant="outlined" onClick={prevPage}>Retake</Button>
            <Button variant="contained" onClick={nextPage}>Confirm</Button>
          </div>
        </div>
      }

      {page === x++ &&
        <div className='flexColumn justifyStart'>
          <div className='flexRow'>
            <PermIdentity /><div className='heading'>ID Verification</div>
          </div>

          <div>Please take a picture of your photo ID</div>

          <div className='flexColumn selfiePanel'>
            <img alt='' src='/verify-id.png' />
          </div>

          <div className='flexRow'>
            <Button variant="outlined" onClick={prevPage}>Prev</Button>
            <Button variant="contained" onClick={nextPage}>Next</Button>
          </div>
        </div>
      }

      {page === x++ &&
        <div className='flexColumn justifyStart'>
          <div className='flexRow'>
            <PermIdentity /><div className='heading'>ID Verification</div>
          </div>

          <div>Please confirm that you clearly see your ID and photo</div>

          <div className='flexColumn selfiePanel'>
            <img alt='' src='/verify-id.png' />
          </div>

          <div className='flexRow'>
            <Button variant="outlined" onClick={prevPage}>Retake</Button>
            <Button variant="contained" onClick={nextPage}>Confirm</Button>
          </div>
        </div>
      }

      {page === x++ &&
        <div className='flexColumn justifyStart'>
          <div className='flexRow'>
            <PermIdentity /><div className='heading'>ID Verification</div>
          </div>

          <div>Please take a video confirming your name</div>

          <div className='flexColumn selfiePanel'>
            <img alt='' src='/verify-person.png' />
          </div>

          <div className='flexRow'>
            <Button variant="outlined" onClick={prevPage}>Prev</Button>
            <Button variant="contained" onClick={nextPage}>Next</Button>
          </div>
        </div>
      }

      {page === x++ &&
        <div className='flexColumn justifyStart'>
          <div className='flexRow'>
            <PermIdentity /><div className='heading'>ID Verification</div>
          </div>

          <div>Please confirm that you clearly see you and hear you</div>

          <div className='flexColumn selfiePanel'>
            <img alt='' src='/verify-person.png' />
          </div>

          <div className='flexRow'>
            <Button variant="outlined" onClick={prevPage}>Retake</Button>
            <Button variant="contained" onClick={nextPage}>Confirm</Button>
          </div>
        </div>
      }

      {page === x++ &&
        <div className='flexColumn justifyStart'>
          <div className='flexRow'>
            <PermIdentity /><div className='heading'>ID Verification</div>
          </div>

          <div className='mb-2'>Your ID verification is complete!</div>

          <div className='mb-2'>Please click OK to continue</div>

          <Link to="/player/profile/confirm"><Button variant="contained">OK</Button></Link>
        </div>
      }

    </div>
  );
}

import '../App.css';
import * as React from 'react';
import { useState } from 'react';
import SimpleAppBar from "../appbars/SimpleAppBar";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";

export default function PlayerConnectData() {
  const [page] = useState(0);

  let x = 0;

  return (
    <div className="App flexColumn justifyStart">
      <SimpleAppBar />
      {page === x++ &&
        <div className='flexColumn justifyStart'>
          <div className='heading'>Connect Your Data</div>

          <div>Please select the data you would like to connect</div>

          <div className='flexColumn'>
            <Link to='/player/connect-data/whoop'><div className='flexColumn dataButton'>
              <img alt='' src='/data-whoop.png'/>
            </div></Link>
            <Link to='/not-implemented'><div className='flexColumn dataButton'>
              <img alt='' src='/data-garmin.png'/>
            </div></Link>
            <Link to='/not-implemented'><div className='flexColumn dataButton'>
              <img alt='' src='/data-fitbit.png'/>
            </div></Link>
          </div>

          <Link to='/player/tasks?step=4'><Button variant='outlined'>Skip for now</Button></Link>

          <div className='mt-1'>Click OK when you have connected your data.</div>

          <Link to='/player/tasks?step=4'><Button variant='contained'>OK</Button></Link>
        </div>
      }

    </div>
  );
}

import '../App.css';
import * as React from 'react';
import SimpleAppBar from "../appbars/SimpleAppBar";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

export default function SignupAgent() {

  return (
    <div className="App flexColumn justifyStart">
      <SimpleAppBar />

      <div className="title">Agent Signup</div>

      <div>this feature is coming soon.</div>

      <Link to="/"><Button variant="outlined">OK</Button></Link>

    </div>
  );
}

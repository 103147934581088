import '../App.css';
import * as React from 'react';
import { useState } from 'react';
import SimpleAppBar from "../appbars/SimpleAppBar";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import { ContactMail, Done, PermIdentity, Work } from "@mui/icons-material";
import Box from "@mui/material/Box";
import { useFormik } from "formik";
import * as yup from "yup";
import MyTextField from "../forms/MyTextField";

const validationSchema = yup.object({
  profession: yup.string().min(5, 'Field is too short').required('This field is required'),
  employmentStatus: yup.string().min(5, 'Field is too short').required('This field is required'),
  employer: yup.string().min(5, 'Field is too short').required('This field is required'),
  achievements: yup.string(),
  other: yup.string(),
});

export default function PlayerProfileProfessional() {
  const [page, setPage] = useState(0);

  const navigate = useNavigate();
  const prevPage = async () => {setPage(page - 1)};
  const nextPage = async () => {setPage(page + 1)};

  const formik = useFormik({
    initialValues: {profession: '', employmentStatus: '', employer: '', achievements: '', other: ''},
    onSubmit: (values) => {console.log(JSON.stringify(values));},
    validationSchema: validationSchema,
  });

  let x = 0;

  return (
    <div className="App flexColumn justifyStart">
      <SimpleAppBar />

      {page === x++ &&
        <div className='flexColumn justifyStart'>
          <div className='heading'>Complete your profile</div>

          <div>Please click next to continue</div>

          <div className='flexColumn alignStart mt-2 mb-2'>
            <div className='flexRow completed'>
              <ContactMail /><div>Personal Information</div><Done />
            </div>
            <div className='flexRow'>
              <Work /><div>Professional Information</div>
            </div>
            <div className='flexRow'>
              <PermIdentity /><div>ID Verification</div>
            </div>
          </div>

          <div className='flexRow'>
            <Button variant="outlined" onClick={() => navigate(-1)}>Cancel</Button>
            <Button variant="contained" onClick={nextPage}>Next</Button>
          </div>
        </div>
      }

      {page === x++ &&
        <div className='flexColumn justifyStart'>
          <div className='flexRow'>
            <Work /><div className='heading'>Professional Information</div>
          </div>

          <div>Please provide the following information</div>

          <Box component="form" noValidate autoComplete="off">
            <div className='flexColumn mt-2 mb-2 gap-2'>
              <MyTextField formik={formik} name='profession' label='Profession' required={true} />
              <MyTextField formik={formik} name='employmentStatus' label='Employment Status' required={true} />
              <MyTextField formik={formik} name='employer' label='Employer' required={true} />
              <MyTextField formik={formik} name='achievements' label='Achievements' required={false} />
              <MyTextField formik={formik} name='other' label='Other' required={false} />
            </div>
          </Box>

          <div className='flexRow'>
            <Button variant="outlined" onClick={prevPage}>Prev</Button>
            <Button variant="contained" onClick={async () => {
              await formik.submitForm();
              !Object.keys(await formik.validateForm()).length && nextPage();
            }}>Next</Button>
          </div>
        </div>
      }

      {page === x++ &&
        <div className='flexColumn justifyStart'>
          <div className='flexRow'>
            <Work /><div className='heading'>Professional Information</div>
          </div>

          <div className='mb-2'>Your professional information is complete!</div>

          <div className='mb-2'>Please click OK to continue</div>

          <Link to="/player/profile/id-verification"><Button variant="contained">OK</Button></Link>
        </div>
      }

    </div>
  );
}

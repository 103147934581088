import PhoneInput from "react-phone-input-2";
import * as React from "react";

export default function MyPhoneInput(props: {formik: any, name: string, placeholder: string}) {

  return (
    <div className='flexColumn gap-1'>
      <PhoneInput
        value={props.formik.values[props.name]}
        onChange={(x) => props.formik.handleChange({target: {id: props.name, value: x}})}
        onBlur={(x) => props.formik.handleBlur({target: {id: props.name, value: x}})}
        preferredCountries={['gb']}
        onlyCountries={['fr', 'gb', 'es', 'pt', 'au', 'br']}
        masks={{au: '(...) ... ...'}}
        placeholder={props.placeholder}
      />
      {
        props.formik.touched[props.name] && <div className='errorText'>{props.formik.errors[props.name]}</div>
      }
    </div>
  );
}
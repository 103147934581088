import './App.css';
import * as React from 'react';
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";

export default function App() {

  return (
    <div className="App flexColumn justifyStart">

      <img alt='' className='mt-3 mb-3' src='/MyNFA_world_white.png' style={{width: '15em'}}/>

      <Link to="/signup"><Button variant="contained">Sign Up</Button></Link>

      <Link to="/dashboard"><Button variant="outlined">Log In</Button></Link>

    </div>
  );
}

import '../App.css';
import * as React from 'react';
import { useState } from 'react';
import SimpleAppBar from "../appbars/SimpleAppBar";
import Button from "@mui/material/Button";
import { Link, useSearchParams } from "react-router-dom";
import TickButton from "../components/TickButton";

export default function PlayerTasks() {
  const [page] = useState(0);
  const [searchParams] = useSearchParams();
  const step = searchParams.get('step') ? +searchParams.get('step')! : 1;

  let x = 0;

  return (
    <div className="App flexColumn justifyStart">
      <SimpleAppBar />

      {page === x++ &&
        <div className='flexColumn justifyStart'>
          <div className='flexColumn gap-0'>
            <div className='heading'>Welcome to MyNFA World</div>
          </div>

          <div className='mb-2'>Please complete the following tasks to complete your account setup</div>

          <div className='flexColumn'>
            <TickButton to='/player/auth-agent' text='Authorise agent access' disabled={false} ticked={step > 1}/>
            <TickButton to='/player/profile/personal' text='Complete your profile' disabled={step < 2} ticked={step > 2}/>
            <TickButton to='/player/connect-data' text='Connect your data' disabled={step < 3} ticked={step > 3}/>
          </div>

          { step > 3 &&
            <div className='mt-2'>
              <Link to='/dashboard'><Button variant='contained'>Finished</Button>
              </Link>
            </div>
          }
        </div>
      }

      </div>
  );
}

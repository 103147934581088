import * as React from "react";

export default function DotsProgress(props: {max: number, current: number}) {
  let dots = Array.from(Array(props.max).keys());
  return (
    <div className='flexRow gap-3'>
      {dots.map((dot) => (
        <div className={dot + 1 === props.current ? 'dotFilled' : 'dotEmpty'}> </div>
      ))}
    </div>
  )
}
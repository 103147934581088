import '../App.css';
import * as React from 'react';
import { useState } from 'react';
import SimpleAppBar from "../appbars/SimpleAppBar";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import { ContactMail, PermIdentity, Work } from "@mui/icons-material";
import Box from "@mui/material/Box";
import { useFormik } from "formik";
import * as yup from "yup";
import MyTextField from "../forms/MyTextField";
import MyPhoneInput from "../forms/MyPhoneInput";

const validationSchema1 = yup.object({
  firstName: yup.string().min(2, 'Name is too short').required('This field is required'),
  middleName: yup.string().min(2, 'Name is too short'),
  lastName: yup.string().min(2, 'Name is too short').required('This field is required'),
  nickName: yup.string().min(2, 'Name is too short'),
  otherName: yup.string().min(2, 'Name is too short'),
});

const validationSchema2 = yup.object({
  dateOfBirth: yup.string().min(10, 'Field is too short').required('This field is required'),
  nationality: yup.string().min(2, 'Field is too short').required('This field is required'),
  gender: yup.string().required('This field is required').required('This field is required'),
});

const validationSchema3 = yup.object({
  alternateMobile: yup.string(),
  alternateEmail: yup.string().email('Must be a valid email address'),
});

const validationSchema4 = yup.object({
  addressLine1: yup.string().min(3, 'Field is too short').required('This field is required'),
  addressLine2: yup.string().min(3, 'Field is too short'),
  addressCity: yup.string().min(3, 'Field is too short').required('This field is required'),
  addressCounty: yup.string().min(3, 'Field is too short').required('This field is required'),
  addressPostcode: yup.string().min(4, 'Field is too short').required('This field is required'),
  addressCountry: yup.string().min(3, 'Field is too short').required('This field is required'),
});

export default function PlayerProfilePersonal() {
  const [page, setPage] = useState(0);

  const navigate = useNavigate();
  const prevPage = async () => {setPage(page - 1)};
  const nextPage = async () => {setPage(page + 1)};

  const formik1 = useFormik({
    initialValues: {firstName: '', middleName: '', lastName: '', nickName: '', otherName: ''},
    onSubmit: (values) => {console.log(JSON.stringify(values));},
    validationSchema: validationSchema1,
  });

  const formik2 = useFormik({
    initialValues: {dateOfBirth: '', nationality: '', gender: ''},
    onSubmit: (values) => {console.log(JSON.stringify(values));},
    validationSchema: validationSchema2,
  });

  const formik3 = useFormik({
    initialValues: {alternateMobile: '', alternateEmail: ''},
    onSubmit: (values) => {console.log(JSON.stringify(values));},
    validationSchema: validationSchema3,
  });

  const formik4 = useFormik({
    initialValues: {addressLine1: '', addressLine2: '', addressCity: '', addressCounty: '', addressPostcode: '', addressCountry: ''},
    onSubmit: (values) => {console.log(JSON.stringify(values));},
    validationSchema: validationSchema4,
  });

  let x = 0;

  return (
    <div className="App flexColumn justifyStart">
      <SimpleAppBar />

      {page === x++ &&
        <div className='flexColumn justifyStart'>
          <div className='heading'>Complete your profile</div>

          <div>Please click next to continue</div>

          <div className='flexColumn alignStart mt-2 mb-2'>
            <div className='flexRow'>
              <ContactMail /><div>Personal Information</div>
            </div>
            <div className='flexRow'>
              <Work /><div>Professional Information</div>
            </div>
            <div className='flexRow'>
              <PermIdentity /><div>ID Verification</div>
            </div>
          </div>

          <div className='flexRow'>
            <Button variant="outlined" onClick={() => navigate(-1)}>Cancel</Button>
            <Button variant="contained" onClick={nextPage}>Next</Button>
          </div>
        </div>
      }

      {page === x++ &&
        <div className='flexColumn justifyStart'>
          <div className='flexRow'>
            <ContactMail /><div className='heading'>Personal Information</div>
          </div>

          <div>Please provide the following information</div>

          <Box component="form" noValidate autoComplete="off">
            <div className='flexColumn mt-2 mb-2 gap-2'>
              <MyTextField formik={formik1} name='firstName' label='First Name' required={true} />
              <MyTextField formik={formik1} name='middleName' label='Middle Name' required={false} />
              <MyTextField formik={formik1} name='lastName' label='Last Name' required={true} />
              <MyTextField formik={formik1} name='nickName' label='Nick Name' required={false} />
              <MyTextField formik={formik1} name='otherName' label='Other Name' required={false} />
            </div>
          </Box>

          <div className='flexRow'>
            <Button variant="outlined" onClick={prevPage}>Prev</Button>
            <Button variant="contained" onClick={async () => {
              await formik1.submitForm();
              !Object.keys(await formik1.validateForm()).length && nextPage();
            }}>Next</Button>
          </div>
        </div>
      }

      {page === x++ &&
        <div className='flexColumn justifyStart'>
          <div className='flexRow'>
            <ContactMail /><div className='heading'>Personal Information</div>
          </div>

          <div>Please provide the following information</div>

          <Box component="form" noValidate autoComplete="off">
            <div className='flexColumn mt-2 mb-2 gap-2'>
              <MyTextField formik={formik2} name='dateOfBirth' label='Date of Birth' required={true} />
              <MyTextField formik={formik2} name='nationality' label='Nationality' required={true} />
              <MyTextField formik={formik2} name='gender' label='Gender' required={true} />
            </div>
          </Box>

          <div className='flexRow'>
            <Button variant="outlined" onClick={prevPage}>Prev</Button>
            <Button variant="contained" onClick={async () => {
              await formik2.submitForm();
              !Object.keys(await formik2.validateForm()).length && nextPage();
            }}>Next</Button>
          </div>
        </div>
      }

      {page === x++ &&
        <div className='flexColumn justifyStart'>
          <div className='flexRow'>
            <ContactMail /><div className='heading'>Personal Information</div>
          </div>

          <div>Please provide the following information</div>

          <Box component="form" noValidate autoComplete="off">
            <div className='flexColumn mt-2 mb-2 gap-2'>
              <MyPhoneInput formik={formik3} name={'alternateMobile'} placeholder={'Alternate Mobile'} />
              <MyTextField formik={formik3} name={'alternateEmail'} label={'Alternate Email'} required={false} />
            </div>
          </Box>

          <div className='flexRow'>
            <Button variant="outlined" onClick={prevPage}>Prev</Button>
            <Button variant="contained" onClick={async () => {
              await formik3.submitForm();
              !Object.keys(await formik3.validateForm()).length && nextPage();
            }}>Next</Button>
          </div>
        </div>
      }

      {page === x++ &&
        <div className='flexColumn justifyStart'>
          <div className='flexRow'>
            <ContactMail /><div className='heading'>Personal Information</div>
          </div>

          <div>Please provide your main address</div>

          <Box component="form" noValidate autoComplete="off">
            <div className='flexColumn mt-2 mb-2 gap-2'>
              <MyTextField formik={formik4} name='addressLine1' label='Address Line 1' required={true} />
              <MyTextField formik={formik4} name='addressLine2' label='Address Line 2' required={false} />
              <MyTextField formik={formik4} name='addressCity' label='Town / City' required={true} />
              <MyTextField formik={formik4} name='addressCounty' label='County / Municipality' required={true} />
              <MyTextField formik={formik4} name='addressPostcode' label='Postcode' required={true} />
              <MyTextField formik={formik4} name='addressCountry' label='Country' required={true} />
            </div>
          </Box>

          <div className='flexRow'>
            <Button variant="outlined" onClick={prevPage}>Prev</Button>
            <Button variant="contained" onClick={async () => {
              await formik4.submitForm();
              !Object.keys(await formik4.validateForm()).length && nextPage();
            }}>Next</Button>
          </div>
        </div>
      }

      {page === x++ &&
        <div className='flexColumn justifyStart'>
          <div className='flexRow'>
            <ContactMail /><div className='heading'>Personal Information</div>
          </div>

          <div className='mb-2'>Your personal information is complete!</div>

          <div className='mb-2'>Please click OK to continue</div>

          <Link to="/player/profile/professional"><Button variant="contained">OK</Button></Link>
        </div>
      }

    </div>
  );
}

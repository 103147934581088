import '../App.css';
import * as React from 'react';
import { useState } from 'react';
import SimpleAppBar from "../appbars/SimpleAppBar";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import * as yup from "yup";
import { useFormik } from "formik";
import MyPhoneInput from "../forms/MyPhoneInput";
import MyTextField from "../forms/MyTextField";
import MyConfirmField from "../forms/MyConfirmField";

const validationSchema = yup.object({
  agentFirstName: yup.string().required('This field is required')
    .min(2, 'Name is too short'),
  agentLastName: yup.string().required('This field is required')
    .min(2, 'Name is too short'),
  agentEmail: yup.string().required('This field is required')
    .email('Must be a valid email address'),
  agentMobile: yup.string().required('This field is required'),
  agentConfirm: yup.boolean().isTrue('You must accept this option'),
});

export default function PlayerAuthAgent() {
  const [page, setPage] = useState(0);

  const navigate = useNavigate();
  // const prevPage = async () => {setPage(page - 1)};
  const nextPage = async () => {setPage(page + 1)};

  const formik = useFormik({
    initialValues: {agentFirstName: '', agentLastName: '', agentEmail: '', agentMobile: '', agentConfirm: false},
    validationSchema: validationSchema,
    onSubmit: (values) => {console.log(JSON.stringify(values));},
  });

  let x = 0;

  return (
    <div className="App flexColumn justifyStart">
      <SimpleAppBar/>

      {page === x++ &&
        <div className='flexColumn justifyStart'>
          <div className='heading'>Authorise Agent</div>

          <div>Please provide the details of your agent</div>

          <Box component="form" noValidate autoComplete="off">
            <div className='flexColumn gap-2'>
              <MyTextField formik={formik} name='agentFirstName' label='Agent First Name' required={true} />
              <MyTextField formik={formik} name='agentLastName' label='Agent Last Name' required={true} />
              <MyTextField formik={formik} name='agentEmail' label='Agent Email' required={true} />
              <MyPhoneInput formik={formik} name='agentMobile' placeholder={'Agent Mobile'}/>
            </div>
          </Box>

          <MyConfirmField formik={formik} name='agentConfirm' label='I confirm that I authorise this person to be an administrator on my MyNFA World account' maxWidth='350px' />

          <div className='flexRow mt-1'>
            <Button variant="outlined" onClick={() => navigate(-1)}>Cancel</Button>
            <Button variant="contained" onClick={async () => {
              await formik.submitForm();
              !Object.keys(await formik.validateForm()).length && nextPage();
            }}>Next</Button>
          </div>
        </div>
      }

      {page === x++ &&
        <div className='flexColumn justifyStart'>
          <div className='heading'>Agent Authorised</div>

          <div>Your agent has been authorised.</div>
          <div>Please click OK to continue</div>

          <Link to='/player/tasks?step=2'><Button variant="contained">OK</Button></Link>
        </div>
      }

    </div>
  );
}

import '../App.css';
import * as React from 'react';
import { useState } from 'react';
import SimpleAppBar from "../appbars/SimpleAppBar";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";

export default function PlayerConnectDataWhoop() {
  const [page, setPage] = useState(0);

  const prevPage = async () => {setPage(page - 1)};
  const nextPage = async () => {setPage(page + 1)};

  let x = 0;

  return (
    <div className="App flexColumn justifyStart">
      <SimpleAppBar/>

      {page === x++ &&
        <div className='flexColumn justifyStart'>
          <div className='heading'>Connect Your Data</div>

          <div>Please log in to Whoop to connect to MyNFA World</div>

          <Link to='/player/connect-data/whoop'>
            <div className='flexColumn gap-1 dataButton' onClick={nextPage}>
              <div style={{color: 'black'}}>Log in to</div>
              <img alt='' src='/data-whoop.png'/>
            </div>
          </Link>

        </div>
      }

      {page === x++ &&
        <div className='flexColumn justifyStart'>
          <div className='heading'>Whoop</div>

          <img alt='' src='/mock-whoop-1.png'/>

          <div className='flexRow'>
            <Button variant="outlined" onClick={prevPage}>Prev</Button>
            <Button variant="contained" onClick={nextPage}>Next</Button>
          </div>

        </div>
      }

      {page === x++ &&
        <div className='flexColumn justifyStart'>
          <img alt='' src='/mock-whoop-2.png'/>

          <div className='flexRow'>
            <Button variant="outlined" onClick={prevPage}>Prev</Button>
            <Button variant="contained" onClick={nextPage}>Next</Button>
          </div>

        </div>
      }

      {page === x++ &&
        <div className='flexColumn justifyStart'>
          <div className='heading'>Whoop</div>

          <div>Your Whoop data is now connected.</div>

          <div>You can change this at any time via "Connect your Data".</div>

          <Link to='/player/connect-data'><Button variant='contained'>OK</Button></Link>
        </div>
      }

    </div>
  );
}

import '../App.css';
import * as React from 'react';
import { useState } from 'react';
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import ReactCountryFlag from "react-country-flag"
import { getI18n, useTranslation } from "react-i18next";
import SimpleAppBar from "../appbars/SimpleAppBar";
import DotsProgress from "../components/DotsProgress";

export default function Signup() {
  const {t} = useTranslation();
  const [page, setPage] = useState(0);

  const prevPage = async () => {setPage(page - 1)};
  const nextPage = async () => {setPage(page + 1)};

  let x = 0;

  return (
    <div className="App flexColumn justifyStart">
      <SimpleAppBar />

      {page === x++ &&
        <div>
          <div className="title">{t('app.language')}</div>

          <div className='flexColumn'>
            <Button variant="contained" className='buttonWide' onClick={() => {getI18n().changeLanguage('en'); nextPage();}}>
              <div className='flexRow justifyStart' style={{width: '100%'}}><ReactCountryFlag countryCode="GB" svg style={{width: '2em', height: '2em'}}/> English</div>
            </Button>
            <Button variant="contained" className='buttonWide' onClick={() => {getI18n().changeLanguage('es'); nextPage();}}>
              <div className='flexRow justifyStart' style={{width: '100%'}}><ReactCountryFlag countryCode="ES" svg style={{width: '2em', height: '2em'}}/> Español</div>
            </Button>
            <Button variant="contained" className='buttonWide' onClick={() => {getI18n().changeLanguage('pr'); nextPage();}}>
              <div className='flexRow justifyStart' style={{width: '100%'}}><ReactCountryFlag countryCode="PT" svg style={{width: '2em', height: '2em'}}/> Português</div>
            </Button>
          </div>
        </div>
      }

      {page === x++ &&
        <div>
          <div className='flexColumn'>
            <div className='flexColumn infoPage'>
              <div>{t('signup.info1')}</div>
            </div>
            <DotsProgress max={4} current={1} />
            <div className='flexRow'>
              <Button variant="outlined" onClick={prevPage}>Back</Button>
              <Button variant="contained" onClick={nextPage}>Next</Button>
            </div>
          </div>
        </div>
      }

      {page === x++ &&
        <div>
          <div className='flexColumn'>
            <div className='flexColumn infoPage'>
              <div>{t('signup.info2')}</div>
            </div>
            <DotsProgress max={4} current={2} />
            <div className='flexRow'>
              <Button variant="outlined" onClick={prevPage}>Back</Button>
              <Button variant="contained" onClick={nextPage}>Next</Button>
            </div>
          </div>
        </div>
      }

      {page === x++ &&
        <div>
          <div className='flexColumn'>
            <div className='flexColumn infoPage'>
              <div>{t('signup.info3')}</div>
            </div>
            <DotsProgress max={4} current={3} />
            <div className='flexRow'>
              <Button variant="outlined" onClick={prevPage}>Back</Button>
              <Button variant="contained" onClick={nextPage}>Next</Button>
            </div>
          </div>
        </div>
      }

      {page === x++ &&
        <div>
          <div className='flexColumn'>
            <div className='flexColumn infoPage'>
              <div>{t('signup.info4')}</div>
            </div>
            <DotsProgress max={4} current={4} />
            <div className='flexRow'>
              <Button variant="outlined" onClick={prevPage}>Back</Button>
              <Button variant="contained" onClick={nextPage}>Next</Button>
            </div>
          </div>
        </div>
      }

      {page === x++ &&
        <div>
          <div className='title'>Sign up to MyNFA World</div>

          <div className='mb-2'>Please choose from one of the following options</div>

          <div className='flexColumn mb-2'>
            <Link to="/signup/player"><Button variant="contained">Create a personal account</Button></Link>
            <Link to="/signup/agent"><Button variant="outlined">Create an account on behalf of a client</Button></Link>
          </div>

          <Link to='/login'>I have an account already</Link>
        </div>
      }
    </div>
  );
}

import './App.css';
import * as React from 'react';
import ResponsiveAppBar from "./appbars/ResponsiveAppBar";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

export default function Partnerships() {

  return (
    <div className="App flexColumn justifyStart">
      <ResponsiveAppBar />

      <div className="title">Partnerships</div>

      <div>this feature is coming soon.</div>

      <Link to="/"><Button variant="outlined">OK</Button></Link>

    </div>
  );
}

import { Checkbox, FormControlLabel } from "@mui/material";
import * as React from "react";

export default function MyConfirmField(props: { formik: any, name: string, label: string, maxWidth: string }) {
  return (
    <div style={{maxWidth: '350px'}}>
      <FormControlLabel
        label={props.label}
        control={
          <Checkbox id={props.name} style={{color: 'white'}}
                    onChange={(e) => props.formik.handleChange(e)}
                    onBlur={(e) => props.formik.handleBlur(e)}
          />
        }
      />
      <div className='errorText'>{props.formik.touched[props.name] && props.formik.errors[props.name]}</div>
    </div>
  );
}